
import footerMenu from '@/footerMenu.json'
import { EventBus } from '@/utils/event-bus.js';
import globalEventListeners from '@/mixins/globalEventListeners';
import CardSubscribe from '@/components/card/CardSubscribe.vue'

export default {
  mixins: [globalEventListeners],
  components: {
    CardSubscribe
  },
  data() {
    return {
      footerMenuIndex: null,
      contactCard: {},
      contactButton: {},
      footerMenuGroup: {},
      socialLinks: [],
      subMenuGroup: [],
      show: false,
      isMobile: false,
      isModalOpen: false,
      pageData: null,
      debug: process.env.NODE_ENV === 'development'
    }
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.breakpoints, {
        passive: true,
      })
    }
    EventBus.$off('open-modal', this.openModal);
    this.removeGlobalEventListeners();
    document.removeEventListener('keydown', this.handleKeyPress);


  },
  async mounted() {
    this.breakpoints()
    window.addEventListener('resize', this.breakpoints, {
      passive: true,
    })
    await this.getData()
    
    // Listen Global Events
    EventBus.$on('open-modal', this.openModal);
    this.addGlobalEventListeners();
    document.addEventListener('keydown', this.handleKeyPress);

  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
    layout() {
      return this.$route.matched.length > 0 ? this.$route.matched[0].components.default.options.layout : 'default';
    },
    hasLeadFormSingle() {
      return this.$route.matched.some(
        (route) => route.components.default.name === 'LeadFormSingle'
      );
    },
    partnerInfo() {
      const routeName = this.$route.name;
      if (this.debug) {
        console.log('Current route:', routeName);
      }

      if (!this.pageData) {
        if (this.debug) {
          console.log('No page data available');
        }
        return { name: '', email: '', isVertical: false };
      }

      const info = {
        name: this.pageData.partner_name || '',
        email: this.pageData.partner_email || '',
        isVertical: this.pageData.isVertical || false
      };

      if (this.debug) {
        console.log('Partner info:', info);
        console.log('Page data:', this.pageData);
      }

      return info;
    }
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(to) {
        // Check if we're on a solutions or verticals page by path
        const path = to.path;
        if (this.debug) {
          console.log('Current path:', path);
        }

        try {
          const slug = path.replace(/^\/|\/$/g, '');
          if (this.debug) {
            console.log('Checking slug:', slug);
          }

          // First try to get the data with isVertical=true
          const { data } = await this.$axios.get(
            `/solutions?slug=${slug}`
          );
          
          if (data && data.length > 0) {
            if (this.debug) {
              console.log('API Response:', data[0]);
            }
            this.pageData = data[0];
          } else {
            if (this.debug) {
              console.log('No data found for slug:', slug);
            }
            this.pageData = null;
          }
        } catch (error) {
          console.error('Error fetching page data:', error);
          this.pageData = null;
        }
      }
    }
  },
  created() {
    EventBus.$on('open-modal', this.openModal);
    this.addGlobalEventListeners();


  },
  methods: {
    stripTrailingSlash(str) {
      return str.length > 1 && str.endsWith('/') ? str.slice(0, -1) : str
    },
    async getData() {
      try {
        const response = await footerMenu
        this.contactCard = await response.ContactCard
        this.contactButton = this.contactCard.ctaButton

        this.footerMenuGroup = response.footerMenuGroup

        this.socialLinks = response.Social.socialLinks

        this.subMenuGroup = response.subMenuGroup
      } catch (err) {
        return false
      }

    },
    trim(value) {
      return value.split(' ').join('')
    },
    breakpoints() {
      this.isMobile = window.innerWidth < 992
    },
    accordionFooter(index) {
      this.footerMenuIndex === index
        ? (this.footerMenuIndex = null)
        : (this.footerMenuIndex = index)
    },
    closeModalForm() {
      this.$refs.modalRef.closedModal();
      // this.$refs.modalRef2.closedModal();
      this.isModalOpen = false;

    },
    openModal() {
      this.isModalOpen = true;
      globalModalButton.click();

    },

    // addGlobalEventListeners() {
    //   const triggers = document.querySelectorAll('.contact-form-trigger');
    //   triggers.forEach(trigger => {
    //     trigger.addEventListener('click', () => {
    //       EventBus.$emit('open-modal');
    //     });
    //   });
    // },

    removeGlobalEventListeners() {
      const triggers = document.querySelectorAll('.contact-form-trigger');
      triggers.forEach(trigger => {
        trigger.removeEventListener('click', () => {
          EventBus.$emit('open-modal');
        });
      });
    },
 
    handleKeyPress(event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.closeModalForm();
      }
    },

  },
}
