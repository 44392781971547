import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=24d6cdde"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"
import style0 from "./Footer.vue?vue&type=style&index=0&id=24d6cdde&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UtilSocial: require('/srv/app/components/util/Social.vue').default,Button: require('/srv/app/components/button/Button.vue').default,FormLeadFormSingle: require('/srv/app/components/form/LeadFormSingle.vue').default,Modal: require('/srv/app/components/modal/Modal.vue').default,Button: require('/srv/app/components/button/Button.vue').default,ButtonUp: require('/srv/app/components/button/ButtonUp.vue').default,Footer: require('/srv/app/components/footer/Footer.vue').default})
